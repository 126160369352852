//  @import '../../node_modules/bootstrap/scss/functions';
//  @import '../../node_modules/bootstrap/scss/variables';
//$primary: #CAA035;
// scheme1 - https://coolors.co/19535f-0b7a75-7b2d26-efb4ac-f0f3f5

//  new scheme is https://coolors.co/19535f-f6c253-f0f3f5-21aaba-feb9b4

// new scheme
// mapped to green gold ahariGrey teal and blush

//  $primary:   #f1d4d3; // #efb4ac;
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&family=Roboto&display=swap');
$primary:   #feb9b4;
$secondary: #d8d8d8;
$teal:      #21aaba;


$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    //  "medium":     $medium,
    "green":      #19535f,
    "gold":       #f6c253,
    "ahariGrey":  #f0f3f5,
    "ahariGreyn": #f6f6ea,
    "teal":       $teal,
    "blush":      #feb9b4,
    "starBurst":   #e899af,
    "schoolGyal": #7c2b25,
    "plum":       #9F4068,
    "midnight":   #0d2c33,
    "terracotta": #db8e71
  ),
  $theme-colors
);

$link-color: $teal;

$font-family-base: "Open Sans";
//  $headings-font-family: "Argent Lie"


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
